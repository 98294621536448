// @ts-nocheck
import { PortableText, PortableTextComponents } from '@portabletext/react'
import { useEffect, useState } from 'react'
import sanityClient from '../sanity-client'
import SanityImage from './SanityImage'
import PulseLoader from "react-spinners/PulseLoader"
// @ts-nocheck

const components: PortableTextComponents = {
  types: {
    image: SanityImage,
  },
    marks: {
      strong: ({children}) => <span className="font-semibold">{children}</span>,
      link: ({value, children}) => {
        return (
          <a href={value?.href} target={value?.href.includes("plat24.a360.com") ? "_self" : "_blank"} className="underline cursor-pointer text-brightGold hover:opacity-75">
            {children}
          </a>
        )
      },
    },
    block: {
        normal: ({children}) => <p className="mt-2">{children}</p>,
        h5: ({children}) => <h5 className="text-lg font-semibold mt-3 leading-6">{children}</h5>,
        h4: ({children}) => <h4 className="text-lg font-semibold mt-4">{children}</h4>,
        h3: ({children}) => <h4 className="text-xl font-semibold mt-4 mb-4">{children}</h4>,
        h2: ({children}) => <h4 className="text-2xl font-semibold mt-4 mb-4">{children}</h4>,
        h1: ({children}) => <h4 className="text-3xl font-semibold mt-5 mb-5">{children}</h4>,
    },
    list: ({ value, children }) => {
      const { level, listItem } = value;
      if (listItem === 'bullet') {
        if (level === 1 || level === 3) {
          return <ul className="list-disc pl-6 mt-1">{children}</ul>;
        } else {
          return <ul className="list-[circle] pl-4 mt-1">{children}</ul>;
        }
      }
      if (listItem === 'number') {
        return <ol className="list-decimal pl-4 mt-1">{children}</ol>;
      }
      return null;
    },
    listItem: {
      bullet: ({children}) => <li>{children}</li>,
      number: ({children}) => <li>{children}</li>,
      checkmarks: ({children}) => <li>✅ {children}</li>,
    },
  }

    type Props = {
        sanityId:string
        pageTitle:string
    }

    type SanityPageType = {
      title:string;
      body:[];
    }

export default function SanityPage({sanityId, pageTitle}: Props) {
    const [pageContent, setPageContent] = useState({} as SanityPageType)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(()=>{
        // @ts-ignore
        sanityClient.fetch(
                `*[_type == "page" && _id == "${sanityId}"]{title, body}`
        )
      .then((data:any) => {
        setPageContent(data[0])
        setIsLoading(false)
      })
      .catch((err:any) => {
        console.error(err.message)
        setIsLoading(false)
      });
    });

    return (
      <div className="flex flex-col space-y-3 min-h-dvh pb-6">
        <div className="flex flex-col text-left max-w-xl mx-auto px-8 md:px-2">

          <div className="flex flex-col space-y-2">
            <div className="py-2 pb-6 text-xl lg:text-2xl text-white font-bold text-center bg-platBlack">
                {pageTitle}
            </div>
            {/* <div className="font-semibold">{item.heading}</div> */}
            <div className="whitespace-normal">

              {isLoading ? (<PulseLoader color="#000" size={20} /> ) : ''}

              {!isLoading &&
                <PortableText
                    value={pageContent.body}
                    components={components}
                />
              }
            </div>
          </div>
        </div>
      </div>
    )
}