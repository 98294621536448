import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { PulseLoader } from 'react-spinners';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { SponsorType } from '../typings/SponsorType';
import { useSponsorStore } from '../stores/sponsorStore';
import FountainLife from './FountainLife';
import { marked } from 'marked';
import parse from 'html-react-parser'


interface Props {
    closeModal: () => void
    id?: string
}

export default function SponsorDetailDialog(props: Props) {
    const [sponsor, setSponsor] = useState<SponsorType | null>(null);
    const { sponsors } = useSponsorStore();

    useEffect(() => {
        if (props.id) {
            const sponsor = sponsors.find((sponsor) => sponsor.id === props.id);
            if (sponsor) {
                setSponsor(sponsor);
            }
        }
    }, [props.id, sponsors]);

    return (
        <Transition appear show={true} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={props.closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/50" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="relative w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <XMarkIcon onClick={props.closeModal} className="w-8 h-8 text-darkGold absolute right-2 top-2 cursor-pointer opacity-50 hover:opacity-75 active:opacity-100" />
                                {
                                    sponsor
                                        ?
                                        <div className="text-gray-500">
                                            <div className="flex flex-col md:flex-row gap-5 flex-wrap md:flex-nowrap">
                                                {
                                                    sponsor.image
                                                        ?
                                                        <div>
                                                            <img className="w-56 h-36 object-contain bg-white p-4 mb-4" src={sponsor.image} alt={sponsor.name} />
                                                        </div>
                                                        : <></>
                                                }
                                                <div className="w-full">
                                                    <p className="text-xl text-gray-900 font-semibold">{sponsor.name}</p>
                                                    {sponsor.name === 'Fountain Life' ? <FountainLife onWhite={true} /> : <p className="text-base text-gray-900">{sponsor.company_description ? parse(marked(sponsor.company_description).toString()) : ""}</p>}
                                                    <div className="flex space-x-4 mt-4">
                                                        {sponsor.booking_link ? (
                                                            <a 
                                                                href={sponsor.booking_link} 
                                                                className="bg-teal font-semibold text-white px-4 py-2 rounded-md shadow hover:opacity-75"
                                                                target="_blank" 
                                                                rel="noopener noreferrer"
                                                            >
                                                                Book Now
                                                            </a>
                                                        ): (
                                                            <></>
                                                            // <div 
                                                            //     className="bg-gray-500 font-semibold text-white px-4 py-2 rounded-md shadow cursor-not-allowed"
                                                            // >
                                                            //     Booking Link Coming Soon
                                                            // </div>
                                                        )}
                                                        {sponsor.company_url && (
                                                            <a 
                                                                href={sponsor.company_url} 
                                                                className="bg-gold font-semibold text-white px-4 py-2 rounded-md shadow hover:opacity-75"
                                                                target="_blank" 
                                                                rel="noopener noreferrer"
                                                            >
                                                                Visit Website
                                                            </a>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className='flex flex-col items-center m-auto'>
                                            <PulseLoader />
                                        </div>
                                }
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}
