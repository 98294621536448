import { Dialog, Transition } from '@headlessui/react'
import { doc, getDoc } from 'firebase/firestore';
import { Fragment, useEffect, useState } from 'react'
import { db } from '../firebase.config';
import { PulseLoader } from 'react-spinners';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useAttendeeStore } from '../stores/attendeeStore';
import { AttendeeType } from '../typings/AttendeeType';
import { marked } from 'marked';
import parse from 'html-react-parser'


interface Props {
    closeModal: () => void
    id?: string
}

export default function AttendeeDetailDialog(props: Props) {
    const [attendee, setAttendee] = useState<AttendeeType | null>(null);
    const { attendees } = useAttendeeStore();

    useEffect(() => {

        const attendeeDetail = attendees.find(s => s.id == props.id)
        setAttendee(attendeeDetail as AttendeeType);

        // if (attendeeDetail) {
        // } else {
        //     // getDoc(doc(db, "conferences", "I3mLbMt8hNEvngK2jYXy", "attendees", props.id)).then(res => {
        //     //     setAttendee(res.data() as AttendeeType)
        //     // });
        // }
    }, [props.id, attendees])

    return (
        <Transition appear show={true} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={props.closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/50" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="relative w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <XMarkIcon onClick={props.closeModal} className="w-8 h-8 text-darkGold absolute right-2 top-2 cursor-pointer opacity-50 hover:opacity-75 active:opacity-100" />
                                {
                                    attendee
                                        ?
                                        <div className="text-gray-500">
                                            <div className="flex flex-col md:flex-row gap-5 flex-wrap md:flex-nowrap">
                                                {
                                                    attendee.image
                                                        ?
                                                        <div>
                                                            <img className="min-w-56 h-56 aspect-square block rounded-md object-cover bg-gray-200 m-auto mt-6 md:mt-0" src={attendee.image}/>
                                                        </div>
                                                        : <></>
                                                }
                                                <div>
                                                    <p className="text-xl text-gray-900 font-semibold">{attendee.name}</p>
                                                    <p className="text-base text-gray-900">{attendee.title}, {attendee.company}</p>
                                                    {/* <p className="text-base mt-5 text-gray-800 leading-6">
                                                        <span className="text-bold text-gray-500">Talk:&nbsp;&nbsp;</span>
                                                        {speaker.talk}
                                                    </p> */}
                                                    <p className="mt-7 text-gray-700 leading-6">
                                                        {attendee.bio ? parse(marked(attendee.bio).toString()) : ""}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className='flex flex-col items-center m-auto'>
                                            <PulseLoader />
                                        </div>
                                }
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}
