import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface UserState {
  name: string;
  team: string;
  setUser: (name: string, team: string) => void;
}

export const useUserStore = create(
  persist<UserState>(
    (set) => ({
      name: '',
      team: '',
      setUser: (name, team) => set({ name, team }),
    }),
    {
      name: 'user-store',
    }
  )
);