import { create } from 'zustand';
import { SponsorType } from '../typings/SponsorType';

type SponsorStore = {
  sponsors: SponsorType[];
  setSponsors: (sponsors: SponsorType[]) => void;
};

export const useSponsorStore = create<SponsorStore>((set) => ({
  sponsors: [],
  setSponsors: (sponsors) => {
    sponsors.forEach((sponsor) => {
      // sponsor.type = "Gold";
    });
    set({ sponsors });
  },
}));
