import React, { useState } from 'react';
import { useUserStore } from './stores/userStore';
import logo from './assets/plat_logo.png';

export default function Register() {
  const [name, setName] = useState('');
  const [team, setTeam] = useState('');
  const setUser = useUserStore((state: any) => state.setUser);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setUser(name, team);
    // You can add navigation logic here if needed
  };

  return (
    <div className="bg-platBlack min-h-dvh">
      <div className="flex min-h-full flex-1 flex-col justify-top px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            alt="Your Company"
            src={logo}
            className="mx-auto h-10 w-auto"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
            Register your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-white">
                Name
              </label>
              <div className="mt-2">
                <input
                  id="name"
                  name="name"
                  type="text"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label htmlFor="team" className="block text-sm font-medium leading-6 text-white">
                Team
              </label>
              <select
                id="team"
                name="team"
                value={team}
                onChange={(e) => setTeam(e.target.value)}
                className="mt-2 block w-full rounded-md border-0 bg-white/5 py-1.5 pl-3 pr-10 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              >
                <option value="">Select a team</option>
                <option value="Red">Red Team</option>
                <option value="Blue">Blue Team</option>
                <option value="Green">Green Team</option>
              </select>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-gold px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-mediumGold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mediumGold"
              >
                Register
              </button>
            </div>
            {0 && <div className="bg-burgundy text-white"><div className="bg-teal"></div>You are already registered</div>}
          </form>
        </div>
      </div>
    </div>
  );
}