import React from 'react';

const Info: React.FC = () => {
  return (
    <div className="flex flex-col space-y-3 min-h-dvh">
      <div className="flex flex-col">
        <div className="py-2 pb-6 text-xl lg:text-2xl text-white font-bold text-center bg-platBlack border-b border-gray-500">
          MORE / INFO
        </div>
        <div className="flex flex-col space-y-3 p-4 text-left max-w-xl mx-auto">
          <p className="text-2xl font-bold">COMING SOON, INCLUDING:</p>
          <ul className="list-disc">
            <li className="mt-2 text-lg font-semibold">Transportation & Lodging</li>
            <li className="mt-2 text-lg font-semibold">Maps</li>
              <ul className="list-disc ml-6">
                <li className="">St. Regis</li>
                <li>Park Hyatt Aviara</li>
              </ul>
            <li className="mt-2 text-lg font-semibold">Longevity Olympics</li>
            <ul className="list-disc ml-6">
              <li>Submit your scores daily to compete in the 2024 Longevity Olympics</li>
            </ul>
            <li className="mt-2 text-lg font-semibold">Renewals</li>
            <li className="ml-6">Continue your longevity journey with us in Boston: September 26-30, 2025 or secure your 2025 Abundance360 membership: visit renewals.a360.com</li>
            <li className="mt-2 text-lg font-semibold">Live Stream</li>
            <li className="ml-6">[Embedded Zoom video] (registration info?)</li>
            <li className="mt-2 text-lg font-semibold">Our Partners</li>
            <li className="ml-6">Thank you to our Partners</li>
            <li className="ml-6">Partner Logos</li>
          </ul>
        </div>
      </div>

    </div>
  );
};

export default Info;

