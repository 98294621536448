import React, { useEffect } from 'react';
import { useAttendeeStore } from './stores/attendeeStore';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid'
import { AttendeeType } from './typings/AttendeeType';
import AttendeeDetailDialog from './components/AttendeeDetailDialog';
import { useState } from 'react';
import clsx from 'clsx';

export default function Directory() {
  const attendees = useAttendeeStore((state) => state.attendees);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAttendee, setSelectedAttendee] = useState<AttendeeType | null>(null);
  const [filteredAttendees, setFilteredAttendees] = useState<(AttendeeType)[]>([]);
  const filters = ["Faculty", "Staff", "Attendee", "Medical Advisor", "Investment Specialist"]
  const [activeFilter, setActiveFilter] = useState(filters)
  // const toggleFilter = (filterInputs: string[]) => {
  //   let newActiveFilter = [...activeFilter];
  //   filterInputs.forEach(filterInput => {
  //     if (newActiveFilter.includes(filterInput)) {
  //       newActiveFilter = newActiveFilter.filter(f => f !== filterInput);
  //     } else {
  //       newActiveFilter.push(filterInput);
  //     }
  //   });
  //   setActiveFilter(newActiveFilter);
  // };
  useEffect(() => {
    //combine speakers and attendees and sort by name
    const combined = attendees.filter(att => att.app_unlisted!=true).sort((a, b) => a.name!.localeCompare(b.name!))
    console.log(combined)
    setFilteredAttendees(combined)
  }, [attendees])

  useEffect(() => {
   //filter attendees based on active filter
   const filtered = attendees.filter(attendee => {
    if(activeFilter.includes("Staff") && !activeFilter.includes("Faculty")){
      return (activeFilter.includes(attendee.type!) || ["recms0tCUPVT7BMHq", "rectCXD6xFLHd9XCe"].includes(attendee.id))
    }else{
      return activeFilter.includes(attendee.type!)
    }
  }).sort((a, b) => a.name!.localeCompare(b.name!)).filter(att => att.app_unlisted!=true)
   setFilteredAttendees(filtered)
  }, [activeFilter])

  return (
    <div className="flex flex-col items-center space-y-3 pb-6">
        <div className="py-2 pb-6 text-xl lg:text-2xl text-white font-bold text-center bg-platBlack">
          DIRECTORY
        </div>
        <div className="flex flex-row items-center space-x-3 pb-6">
          <button onClick={() => { setActiveFilter(["Faculty", "Medical Advisor", "Investment Specialist", "Staff", "Attendee" ])}} className={clsx("uppercase font-semibold px-3 py-2 hover:opacity-75 active:opacity-100 rounded-md cursor-pointer disabled:opacity-30 disabled:cursor-default text-white", activeFilter.length == 5 ? "bg-indigo-500" : "bg-halfDarkGold"  )}>
            All
          </button>
          <button onClick={() => { setActiveFilter(["Faculty", "Medical Advisor", "Investment Specialist"])}} className={clsx("uppercase font-semibold px-3 py-2 hover:opacity-75 active:opacity-100 rounded-md cursor-pointer disabled:opacity-30 disabled:cursor-default text-white", (activeFilter.includes("Faculty") && activeFilter.length == 3)? "bg-a360red" : "bg-halfDarkGold"  )}>
            Faculty
          </button>
          <button onClick={() => { setActiveFilter(["Attendee"])}} className={clsx("uppercase font-semibold px-3 py-2 hover:opacity-75 active:opacity-100 rounded-md cursor-pointer disabled:opacity-30 disabled:cursor-default text-white", (activeFilter.includes("Attendee") && activeFilter.length == 1)? "bg-gold" : "bg-halfDarkGold"  )}>
            Attendees
          </button>
          <button onClick={() => { setActiveFilter(["Staff"])}} className={clsx("uppercase font-semibold px-3 py-2 hover:opacity-75 active:opacity-100 rounded-md cursor-pointer disabled:opacity-30 disabled:cursor-default text-white", (activeFilter.includes("Staff") && activeFilter.length == 1)? "bg-cyan" : "bg-halfDarkGold"  )}>
            Staff
          </button>
        </div>
        <div className="text-center text-lg text-white">Want to update your information? Please email <a href="mailto:savannah@a360.com" className="text-brightGold hover:opacity-75">savannah@a360.com.</a></div>
        {filteredAttendees.length == 0 && <div className="text-center text-lg text-white">No attendees found, use the filter buttons above.</div>}
    <ul role="list" className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 max-w-screen-lg	mx-auto min-h-dvh	">
      {filteredAttendees.map((attendee: AttendeeType) => {
        return (
        <li
          key={attendee.id}
          className="col-span-1 flex flex-col rounded-lg bg-darkGold text-center shadow cursor-pointer mx-10 my-4 md:mx-4"
          onClick={() => {
            setSelectedAttendee(attendee);
            setOpenDialog(true);
          }}
        >
          <div className="flex flex-1 flex-col p-8 relative">
            <span className={"absolute top-2 right-2 text-white uppercase bold text-sm font-semibold px-2 py-1 rounded" + " bg-" + attendee.color}>{attendee.type}</span>
            <img alt="" src={attendee.image} className="mx-auto h-48 w-48 aspect-square object-cover rounded-full border-2 border-brightGold" />
            <h3 className="mt-6 text-xl font-semibold text-white">{attendee.name}</h3>
            <dl className="mt-1 flex flex-grow flex-col justify-between">
              <dt className="sr-only">Title</dt>
              <dd className="text-lg text-white">{attendee.title}</dd>
              <dt className="sr-only">Company</dt>
              <div className="text-base text-gray-200">
                {attendee.company}
              </div>
            </dl>
          </div>
          <div>
            <div className="-mt-px flex divide-x divide-gray-200">
              {/* <div className="flex w-0 flex-1">
                <a
                  href={`mailto:${attendee.email}`}
                  className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-lg font-semibold text-gray-900"
                >
                  <EnvelopeIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                  Email
                </a>
              </div>
              <div className="-ml-px flex w-0 flex-1">
                <a
                  href={`tel:${attendee.telephone}`}
                  className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-lg font-semibold text-gray-900"
                >
                  <PhoneIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                  Call
                </a>
              </div> */}
            </div>
          </div>
        </li>
      )})}
    </ul>
    {openDialog && <AttendeeDetailDialog id={selectedAttendee?.id} closeModal={() => { setOpenDialog(false) }} />}
    </div>
  )
}