import { useEffect, useState } from "react";
import { useAttendeeStore } from "./stores/attendeeStore";
import classNames from "./util/classNames";
import { AttendeeType } from "./typings/AttendeeType";
import clsx from 'clsx';
import Select, { OnChangeValue } from 'react-select';
import { collection, query, onSnapshot, where, doc, updateDoc } from 'firebase/firestore';
import { db } from './firebase.config';

type ListType = {
    name: string;
    key: string;
    checkedKey: string;
    statusFilters:[boolean] | []
    current: boolean;
}

  export default function LiveList() {
    const { attendees, setAttendee } = useAttendeeStore();
    const [onLiveList, setOnLiveList] = useState(true);
    const [checkinFilters, setCheckinFilters] = useState([true,false]);
    const [attendeeTypes, setAttendeeTypes] = useState<{type:string, color:string}[]>([]);

    const [checkIns, setCheckIns] = useState([
      {
        name:"Checked In",
        key:"checked_in",
      },
      {
        name:"Got Badge",
        key:"got_badge",
      },
      {
        name:"Got Jacket",
        key:"got_jacket",
      },
      {
        name:"Got Welcome Bag",
        key:"got_welcome_bag",
      },
      {
        name:"Got Gift Bag",
        key:"got_gift_bag",
      },
    ]);

    const [lists, setLists] = useState([
        {
          name:'Wednesday Dinner',
          key:'wednesday_dinner',
          checkedKey:'wednesday_dinner_checked',
          statusFilters:[true,false],
          current: true
        },
        {
          name:'Thursday Dinner',
          key:'thursday_dinner',
          checkedKey:'thursday_dinner_checked',
          statusFilters:[true,false],
          current: false
        },
        {
          name: 'Friday AM Bus',
          key: 'sept_friday_am_bus',
          checkedKey: 'sept_friday_am_bus_checked',
          statusFilters:[true,false],
          current: false
        },{
          name: 'Friday PM Bus',
          key: 'sept_friday_pm_bus',
          checkedKey: 'sept_friday_pm_bus_checked',
          statusFilters:[true,false],
          current: false
        },
        {
          name: 'Saturday AM Checkin',
          key: 'sept_saturday_am_bus',
          checkedKey: 'sept_saturday_am_checkin_checked',
          statusFilters:[true,false],
          current: false
        },
        {
          name: 'Saturday AM Bus',
          key: 'sept_saturday_am_bus',
          checkedKey: 'sept_saturday_am_bus_checked',
          statusFilters:[true,false],
          current: false
        },
        {
          name: 'Saturday PM Bus',
          key: 'sept_saturday_pm_bus',
          checkedKey: 'sept_saturday_pm_bus_checked',
          statusFilters:[true,false],
          current: false
        },
        {
          name: 'Saturday Flight',
          key: 'sept_saturday_flight',
          checkedKey: 'sept_saturday_flight_checked',
          statusFilters:[true,false],
          current: false
        }
      ]);

    const options = lists.map((tab) => ({
        value: tab.name,
        label: tab.name,
    }));
    
    const activeList = lists.find((list) => list.current);
    
    const handleChange = (selectedOption: OnChangeValue<{ value: string; label: string }, false>) => {
      if (selectedOption) {
          doSetCurrentTab(selectedOption.value);
      }
  };

    const doSetCurrentTab = (listName: string) => {
        //make function to update all lists, make current true, and all others false
        lists.forEach((list) => {
            list.current = list.name === listName;
        });
        setLists([...lists]);
    }

    const checkOrUncheck = async (attendee:AttendeeType, checkKey:string, setTo:boolean) => {
        // Create an object with the single field to update
        let updateData = { [checkKey]: setTo };
        // Save the single field to firebase
        const attendeeDoc = doc(db, "platinum_attendees", attendee.id);
        await updateDoc(attendeeDoc, updateData);
        // Optionally update the local state if needed
        // setAttendee({ ...attendee, ...updateData });
    }

    const setStatusFilter = (newFilters:boolean[]) => {
      if (activeList) {
          activeList.statusFilters = newFilters;
          setLists([...lists]);
      }
    }

    // useEffect(() => {
    //   const attTypes = attendees.reduce((acc:any[], attendee) => {
    //     if (!acc.includes({type: attendee.type!, color: attendee.color})) {
    //       acc.push({type: attendee.type!, color: attendee.color});
    //     }
    //     return acc;
    //   }, [])
    //   setAttendeeTypes(attTypes);
    // }, [attendees]);

    const [hasAccess, setHasAccess] = useState(false);
    const [nameFilter, setNameFilter] = useState("");

    useEffect(() => {
        const accessGranted = localStorage.getItem('accessGranted');
        if (accessGranted === 'true') {
            setHasAccess(true);
        } else {
            promptForPassword();
        }
    }, []);

    const promptForPassword = () => {
        const password = prompt("Please enter the password:");
        if (password === "symbiotic#1") {
            localStorage.setItem('accessGranted', 'true');
            setHasAccess(true);
        } else {
            alert("Incorrect password. Please try again.");
            promptForPassword();
        }
    };

    if (!hasAccess) {
        return <div>Access Denied</div>;
    }

    return (

        <div className="flex flex-col items-center min-h-dvh max-w-4xl mx-auto">
            <div className="py-2 pb-6 text-xl lg:text-2xl text-white font-bold text-center bg-platBlack border-b border-gray-500">
                <span className={clsx("cursor-pointer", onLiveList ? "text-gold" : "text-white")} onClick={() => setOnLiveList(true)}>LIVE LIST</span> || <span className={clsx("cursor-pointer", !onLiveList ? "text-gold" : "text-white")} onClick={() => setOnLiveList(false)}>CHECK IN</span>
            </div>
            {onLiveList ? (
            <>
              <div>
              <div className="sm:hidden">
                  <label htmlFor="tabs" className="sr-only">
                      Select a tab
                  </label>
                  <Select
                      id="tabs"
                      name="tabs"
                      options={options}
                      onChange={handleChange}
                      className="block min-w-[240px] w-full mb-4 text-black text-left rounded-md border-gray-300 focus:border-gold focus:ring-gold"
                      classNamePrefix="react-select"
                  />
              </div>
                <div className="hidden sm:block mx-auto">
                  <nav aria-label="Tabs" className="isolate flex divide-x divide-gray-200 rounded-lg shadow min-w-2xl">
                    {lists.map((tab, tabIdx) => (
                      <a
                        key={tab.name}
                        onClick={() => {
                          doSetCurrentTab(tab.name);
                        }}
                        aria-current={tab.current ? 'page' : undefined}
                        className={classNames(
                          tab.current ? 'text-gray-900 bg-gold' : 'text-gray-500 bg-white hover:text-gray-700',
                          tabIdx === 0 ? 'rounded-l-lg' : '',
                          tabIdx === lists.length - 1 ? 'rounded-r-lg' : '',
                          'group my-6 relative min-w-0 flex-1 overflow-hidden  px-4 py-2 text-center text-sm font-medium focus:z-10 cursor-pointer',
                        )}
                      >
                        <span>{tab.name}</span>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            tab.current ? 'bg-gold' : 'bg-transparent',
                            'absolute inset-x-0 bottom-0 h-0.5',
                          )}
                        />
                      </a>
                    ))}
                  </nav>
                </div>
              </div>
              <div className="mx-2">
                    <p className="text-xl mb-4">CLICK TO FILTER BY STATUS</p>
                    <div className="flex justify-between space-x-4 flex-row align-center">
                      <p onClick={() => setStatusFilter([true,false])} className={clsx("align middle text-base rounded-md bg-gold px-2 py-1 leading-6 text-white cursor-pointer", (activeList!.statusFilters.includes(true) && activeList!.statusFilters.includes(false)) ? "":"opacity-40")}>ALL</p>
                      <p onClick={() => setStatusFilter([true])} className={clsx("text-base rounded-md bg-green-500 px-2 py-1 leading-6 text-white cursor-pointer", (activeList!.statusFilters.includes(true) && !activeList!.statusFilters.includes(false)) ? "":"opacity-40")}>CHECKED IN ({attendees.filter((attendee) => attendee[activeList!.checkedKey]).length} / {attendees.filter((attendee) => attendee[activeList!.key]).length})</p>
                      <p onClick={() => setStatusFilter([false])} className={clsx("text-base rounded-md bg-a360red px-2 py-1 leading-6 text-white cursor-pointer", (!activeList!.statusFilters.includes(true) && activeList!.statusFilters.includes(false)) ? "":"opacity-40")}>NOT CHECKED IN ({attendees.filter((attendee) => attendee[activeList!.key]).length - attendees.filter((attendee) => attendee[activeList!.checkedKey]).length} / {attendees.filter((attendee) => attendee[activeList!.key]).length})</p>
                    </div>
                    <div className="mt-4">
                      <input
                        type="text"
                        placeholder="Filter by name"
                        value={nameFilter}
                        onChange={(e) => setNameFilter(e.target.value)}
                        className="block text-black w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-gold focus:border-gold sm:text-sm"
                      />
                    </div>
              </div>
              <ul role="list" className="divide-y divide-gray-300 mb-10">
              {attendees.filter((attendee) => attendee[activeList!.key])
                          .filter((attendee) => activeList!.statusFilters.includes(attendee[activeList!.checkedKey] ?? false))
                          .filter((attendee) => attendee.name.toLowerCase().includes(nameFilter.toLowerCase()))
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((attendee) => (
                  <li key={attendee.email} className="flex justify-between gap-x-6 py-5 px-2">
                    <div className="flex min-w-0 gap-x-4">
                      <img alt="" src={attendee.image} className="h-20 w-20 flex-none aspect-square object-cover rounded-full bg-gray-800" />
                      <div className="min-w-0 flex-auto">
                        <p className="text-sm font-semibold leading-6 text-white text-left">{attendee.name}</p>
                        <p className="mt-0 mb-2 truncate text-xs leading-5 text-gray-300 text-left">{attendee.company}</p>
                        <div className="text-left"><span className={"text-white text-left uppercase bold text-sm font-semibold px-2 py-1 rounded" + " bg-" + attendee.color}>{attendee.type}</span></div>
                      </div>
                    </div>
                    <div className="shrink-0 sm:flex sm:flex-col sm:items-end">
                        {attendee[activeList!.checkedKey]  ? ( 
                            <p className="text-sm rounded-md bg-green-500 px-2 py-1 leading-6 text-white cursor-pointer" onClick={()=> {checkOrUncheck(attendee,activeList!.checkedKey,false)}}>CHECKED IN X</p>
                        ) : (
                            <p className="text-sm rounded-md bg-a360red px-2 py-1 leading-6 text-white cursor-pointer" onClick={()=> {checkOrUncheck(attendee,activeList!.checkedKey,true)}}>CLICK TO CHECK IN</p>
                        )}
                    </div>
                  </li>
                ))}
              </ul>
            </>
            ):(
              <>
                <div className="mx-2">
                    <p className="text-xl mb-4">CLICK TO FILTER BY STATUS</p>
                    <div className="flex justify-between space-x-4 flex-row align-center">
                      <p onClick={() => setCheckinFilters([true,false])} className={clsx("align middle text-base rounded-md bg-gold px-2 py-1 leading-6 text-white cursor-pointer", (checkinFilters.includes(true) && checkinFilters.includes(false)) ? "":"opacity-40")}>ALL</p>
                      <p onClick={() => setCheckinFilters([true])} className={clsx("text-base rounded-md bg-green-500 px-2 py-1 leading-6 text-white cursor-pointer", (checkinFilters.includes(true) && !checkinFilters.includes(false)) ? "":"opacity-40")}>CHECKED IN ({attendees.filter((attendee) => attendee.checked_in ?? false).length} / {attendees.length})</p>
                      <p onClick={() => setCheckinFilters([false])} className={clsx("text-base rounded-md bg-a360red px-2 py-1 leading-6 text-white cursor-pointer", (!checkinFilters.includes(true) && checkinFilters.includes(false)) ? "":"opacity-40")}>NOT CHECKED IN ({attendees.filter((attendee) => !attendee.checked_in).length} / {attendees.length})</p>
                    </div>
                    <div className="mt-4">
                      <input
                        type="text"
                        placeholder="Filter by name"
                        value={nameFilter}
                        onChange={(e) => setNameFilter(e.target.value)}
                        className="block text-black w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-gold focus:border-gold sm:text-sm"
                      />
                    </div>
                    {/* <div className="mt-4">
                      <p className="text-xl mb-4">FILTER BY TYPE</p>
                      {attendeeTypes.map((type) => (
                        <p key={type.type} className="text-base rounded-md bg-gold px-2 py-1 leading-6 text-white cursor-pointer">{type.type}</p>
                      ))}
                    </div> */}
                </div>
                <ul role="list" className="divide-y divide-gray-300 mb-10">
                {attendees.filter((attendee) => checkinFilters.includes(attendee.checked_in ?? false))
                          .filter((attendee) => attendee.name.toLowerCase().includes(nameFilter.toLowerCase()))
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((attendee) => (
                  <li key={attendee.email} className="flex justify-between gap-x-6 py-5 px-2">
                    <div className="flex min-w-0 gap-x-4">
                      <img alt="" src={attendee.image} className="h-20 w-20 flex-none aspect-square object-cover rounded-full bg-gray-800" />
                      <div className="min-w-0 flex-auto">
                        <p className="text-sm font-semibold leading-6 text-white text-left">{attendee.name}</p>
                        <p className="mt-0 mb-2 truncate text-xs leading-5 text-gray-300 text-left">{attendee.company}</p>
                        <div className="text-left"><span className={"text-white text-left uppercase bold text-sm font-semibold px-2 py-1 rounded" + " bg-" + attendee.color}>{attendee.type}</span></div>
                      </div>
                    </div>
                    <div className="shrink-0 sm:flex sm:flex-col sm:items-end">
                        {attendee.checked_in  ? ( 
                            <p className="text-sm rounded-md bg-green-500 px-2 py-1 leading-6 text-white cursor-pointer" onClick={()=> {checkOrUncheck(attendee,"checked_in",false)}}>CHECKED IN X</p>
                        ) : (
                            <p className="text-sm rounded-md bg-a360red px-2 py-1 leading-6 text-white cursor-pointer" onClick={()=> {checkOrUncheck(attendee,"checked_in",true)}}>CLICK TO CHECK IN</p>
                        )}
                    </div>
                  </li>
                ))}
              </ul>
              </>
            )}
      </div>
    )
  }