import React, { useEffect, useState } from 'react';
import { useSponsorStore } from './stores/sponsorStore';
import plat_square from './assets/plat_square.png';
import { SponsorType } from './typings/SponsorType';
import FountainLife from './components/FountainLife';

const Wellness: React.FC = () => {
  const { sponsors } = useSponsorStore();
  const [sortedSponsors, setSortedSponsors] = useState<SponsorType[]>([]);

  useEffect(() => {
    let spon = sponsors.filter((sponsor) => sponsor.app_visible === true)
    setSortedSponsors(spon.sort((a, b) => a.name!.localeCompare(b.name!)));
  }, [sponsors])

  return (
    <div className="flex flex-col items-center space-y-3 pb-6 min-h-dvh">
      <div className="py-2 pb-6 text-xl lg:text-2xl text-white font-bold text-center bg-platBlack">
        LONGEVITY EXPERIENCES
      </div>
      <div className="flex flex-col flex-wrap justify-center space-y-6 p-4">
        {sortedSponsors.map((sponsor) => (
          <div key={sponsor.id} className="flex flex-col md:flex-row items-center space-x-6 bg-darkGold p-4 rounded-lg shadow-lg w-full max-w-4xl">
            <img
              src={sponsor.image}
              alt={sponsor.name}
              className="w-56 h-36 object-contain bg-white p-4 mb-4"
            />
            <div className="flex flex-col space-y-2 text-left w-full">
              <div className="text-2xl font-bold text-white">{sponsor.name}</div>
              {sponsor.name === 'Fountain Life' ? <FountainLife onWhite={false} /> : <div className="text-white">{sponsor.company_description}</div>}
              <div className="flex space-x-4">
                {sponsor.booking_link ? (
                  <a
                    href={sponsor.booking_link}
                    className="bg-teal font-semibold text-white px-4 py-2 rounded-md shadow hover:opacity-75"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Book Now
                  </a>
                ):(
                  <div 
                      className="bg-gray-500 text-white px-4 py-2 rounded-md shadow cursor-not-allowed"
                  >
                      Booking Link Coming Soon
                  </div>
              )}
                {sponsor.company_url && (
                  <a
                    href={sponsor.company_url}
                    className="bg-gold text-white px-4 py-2 rounded-md shadow hover:bg-mediumGold"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Visit Website
                  </a>
                )}
                {sponsor.offer_link && (
                  <a
                    href={sponsor.offer_link}
                    className="bg-gold text-white px-4 py-2 rounded-md shadow hover:bg-mediumGold"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sign Up
                  </a>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Wellness;