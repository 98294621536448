
import { create } from 'zustand';
import { AttendeeType } from '../typings/AttendeeType';
import dayjs from 'dayjs';
import icon_Profile_Empty from '../assets/icon_Profile-Empty.png';
interface AttendeeState {
  attendees: AttendeeType[];
  setAttendees: (attendees: AttendeeType[]) => void;
  setAttendee: (attendee: AttendeeType) => void;
}

export const useAttendeeStore = create<AttendeeState>((set) => ({
  attendees: [],
  setAttendees: (attendees) => {
    attendees.forEach((attendee) => {
      attendee.image =  attendee.image ? "https://storage.googleapis.com/memberhub-ffcf5.appspot.com/" + attendee.image : icon_Profile_Empty
      switch (attendee.type) {
        case "Staff":
            attendee.color = "cyan"
            break;
        case "Attendee":
            attendee.color = "gold"
            break;
        case "Faculty":
            attendee.color = "a360red"
            break;
        case "Investment Specialist":
            attendee.color = "teal"
            break;
        case "Medical Advisor":
            attendee.color = "burgundy"
            break;
        default:
            attendee.color = "gold"
            break;
      }
    });
    set({ attendees:attendees })
  },
  setAttendee: (attendee) => {
    set((state) => {
      const updatedAttendees = state.attendees.map((a) =>
        a.id === attendee.id ? { ...a, ...attendee } : a
      );
      return { attendees: updatedAttendees };
    });
  }
}));

