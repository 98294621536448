import { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';

export default function FountainLife({onWhite}: {onWhite: boolean}) {
    return (
        <div className="text-white">
            <div className={clsx(' my-2 mb-4', onWhite ? 'text-black' : 'text-white')}>Diagnostics - Offered in San Francisco</div>
            <div className='px-2'>

            <DropdownHeading heading='CGM'>
                <div>Continuous Glucose Monitors offer insights into how diet, exercise, and lifestyle choices impact
                blood sugar levels in real-time. This data allows for optimization of routines for better health
                and performance. Additionally, CGMs can help demonstrate the effects of various foods and
                activities on metabolic health, promoting a more personalized approach to wellness.</div>
            </DropdownHeading>
            
            <DropdownHeading heading='Cardio Wave Machine'>
                <div>The Cardio Wave test assesses cardiovascular health by measuring arterial stiffness and wave
                reflections, providing insights into vascular function and potential disease risk. This non-
                invasive test helps identify early signs of cardiovascular issues, enabling timely interventions
                and lifestyle modifications. Additionally, it offers valuable data for monitoring progress and
                effectiveness of heart health strategies over time.</div>
            </DropdownHeading>
            
            <DropdownHeading heading='NAD Test'>
                <div>The NAD (Nicotinamide Adenine Dinucleotide) test measures levels of this crucial coenzyme
                which is involved in energy production, cellular repair, and metabolic function. Since NAD levels
                decline with age, the test offers insights into the aging process, aiding in the development of
                strategies to potentially slow down age-related decline. Identifying NAD deficiencies enables
                targeted supplementation and lifestyle interventions, enhancing cellular health and reducing
                the risk of metabolic and age-related diseases.</div>
            </DropdownHeading>
            
            <DropdownHeading heading='Sapere Bio: Senescence and Immune Test'>
                <div>The SapereX test is an innovative diagnostic tool designed to measure cellular senescence and
                immune function through analysis of specific biomarkers in a blood sample. Senescence is a
                cellular state of growth arrest and altered metabolism.Cellular senescence is associated with
                nearly all age-related diseases, as well as early dysfunction including a loss of physical
                performance and metabolic changes. Measurement of cellular senescence provides a snapshot
                of an individual’s aging status.  In addition immune system function and it’s ability to clear
                senescent cells is also measured.</div>
            </DropdownHeading>
            
            <DropdownHeading heading='TruAge Epigenetic Age Test'>
                <div>The TruAge Epigenetic Age Test measures biological age by analyzing DNA methylation
                patterns, offering a more accurate reflection of aging than chronological age. This test provides
                an in-depth analysis of over 75 biomarkers related to aging and health that have been
                associated with DNA methylation patterns. These include OMICAge, PACE of Aging, Organ
                Systems Age, Fit Age report, Weight Loss report, Alcohol report, and telomere length.</div>
            </DropdownHeading>
            
            <DropdownHeading heading='Toxins Test'>
                <div>The Mosaic environmental toxin urine test is a screening tool designed to detect and measure a
                wide range of environmental toxins and their metabolites in urine samples. This test analyzes for
                the presence of various harmful substances, including, pesticides, plasticizers, and other
                industrial chemicals that individuals may be exposed to through their environment, diet, or
                lifestyle. By providing a detailed report of toxin levels in the body, the Mosaic test can help
                identify potential sources of exposure.</div>
            </DropdownHeading>
            
            <DropdownHeading heading='CIMT'>
                <div>The Carotid Intima-Media Thickness (CIMT) test is a non-invasive ultrasound procedure that
                measures the thickness of the inner two layers of the carotid artery wall. This test not only
                quantifies the arterial wall thickness it also assesses the presence, location and characteristics
                of atherosclerotic plaque. It is used to assess cardiovascular health by detecting early signs of
                atherosclerosis and plaque that when in the neck is a risk for stroke.</div>
            </DropdownHeading>
            </div>
            
            <div className={clsx('mt-6 mb-4', onWhite ? 'text-black' : 'text-white')}>Therapeutics - Offered in San Francisco and San Diego</div>
            <div className='px-2'>
            <DropdownHeading heading='Fountain Life IV'>
                <div>Fuel your success at the high-energy conference with our Energizer IV Infusion! This
                powerhouse blend is designed for peak performance during those long, demanding hours.
                Featuring the potent Myers' Cocktail, which includes a mix of essential vitamins and minerals,
                the B Complex Forte delivers sustained energy to keep you sharp and focused throughout the
                event. This IV provides a turbocharged boost to mental clarity, a master antioxidant, and
                supports overall well-being.</div>
            </DropdownHeading>
            
            <DropdownHeading heading='NR Therapy - IV addition'>
                <div>An intravenous (IV) administration of nicotinamide riboside (NR) delivers this vitamin B3
                precursor directly into the bloodstream, potentially increasing its bioavailability and efficacy
                compared to oral supplements. Once in the body, NR is converted to nicotinamide adenine
                dinucleotide (NAD+), a crucial coenzyme involved in various cellular processes including energy
                metabolism, DNA repair, and cellular signaling. The potential benefits of NR IV therapy may
                include improved mitochondrial function, enhanced cellular energy production, support for
                healthy aging processes, and possible neuroprotective effects.</div>
            </DropdownHeading>
            </div>
        </div>


    )
}

const DropdownHeading = (props: {heading:string, children: React.ReactNode}) => {
    const [open, setOpen] = useState(false);
    return (
        <div className='flex flex-col bg-mediumGold my-2 p-2 rounded w-full' onClick={() => setOpen(!open)}>
            <div className='flex flex-row'>
                <div className='flex-grow text-white font-bold cursor-pointer'>{props.heading}</div>
                <ChevronDownIcon className={clsx('h-6 w-6 text-white', !open ? 'block' : 'hidden')} />
                <ChevronUpIcon className={clsx('h-6 w-6 text-white', open ? 'block' : 'hidden')} />
            </div>
            <div className={clsx('text-base p-2 rounded', open ? 'block' : 'hidden')}>{props.children}</div>
        </div>
    )
}