
import { create } from 'zustand';
import { SessionType } from '../typings/ScheduleType';
import dayjs from 'dayjs';
import DayJSUtc from 'dayjs/plugin/utc'
import DayJSTimezone from 'dayjs/plugin/timezone'

dayjs.extend(DayJSUtc)
dayjs.extend(DayJSTimezone)

interface ScheduleState {
  sessions: SessionType[];
  setSessions: (sessions: SessionType[]) => void;
}

export const useScheduleStore = create<ScheduleState>((set) => ({
  sessions: [],
  setSessions: (sessions) => {
    sessions.forEach((session) => {
      session.startDayJS = dayjs.unix(session.start.seconds)
      session.endDayJS = dayjs.unix(session.end.seconds)
    });
    console.log("SETTING sessions: ", sessions)
    set({ sessions:sessions })
  },
}));

