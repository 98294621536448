import { DayType } from "../typings/ScheduleType";
import { Fragment, useEffect, useState } from 'react'
import classNames from "../util/classNames";
import { useAttendeeStore } from "../stores/attendeeStore";
import AttendeeDetailDialog from './AttendeeDetailDialog';
import { useSponsorStore } from "../stores/sponsorStore";
import SponsorDetailDialog from "./SponsorDetailDialog";
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid';
import { marked } from 'marked';
import parse from 'html-react-parser'
import dayjs from "dayjs";
type Props ={
  fullDay:DayType
}
export default function ShowSchedule({fullDay}: Props) {

  return (
    <div className="flex flex-col w-full max-w-2xl mx-auto space-y-3">
      <h1 className="mx-auto text-2xl font-bold"></h1>
        <div key={fullDay.date} className="flex flex-col space-y-5">
          <div className="w-full py-5 text-xl uppercase font-bold text-center text-gold">
            {fullDay.date}
          </div>
          <div className="flex flex-col space-y-5">
            {!fullDay.sessions?.length ?
              <div className="text-center font-semibold text-lg">Sessions Loading...</div>
            : fullDay.sessions.map((event, index) => {
              const isSameTime =
                index > 0 && fullDay.sessions[index - 1].start === event.start;
              return (
                <div key={"event"+index} className={classNames(true ? "bg-darkGold" : "bg-slate-100", "rounded")}>
                  {/* <div className="text-right mb-2"> */}
                    {/* {event.patronOnly && <div className="bg-gold text-sm font-semibold uppercase p-2 text-white inline rounded text-center">PATRON MEMBERS ONLY</div>} */}
                  {/* </div> */}
                  <div className="grid grid-cols-3 p-3" key={index}>
                    <div className={`col-span-1 px-1 text-left pt-2 ${isSameTime && "opacity-100"}`}>
                      {event.startDayJS!.tz('America/Los_Angeles').format('h:mma - ') + event.endDayJS!.tz('America/Los_Angeles').format('h:mma')}
                    </div>
                    <div className="flex flex-col col-span-2 space-y-2">
                      {event.title && (
                        <div className="text-left">
                          <div className="font-semibold text-lg break-words">
                            {event.title}
                          </div>
                          {event.location && event.location.length > 0 && (
                            <div className="flex flex-col">
                                <span className="italic text-gray-300">
                                  {event.location} {event.room && ("(" +event.room.trim()+ ")")}
                                </span>
                            </div>
                          )}
                          {(event.description || true) && (
                            <>
                              <div className="flex flex-col mb-2">
                                {/* <span>{parse(event.description)}</span> */}
                              </div>
                              <div className="flex flex-col md:flex-row flex-wrap gap-3">
                                {
                                  event.speakers?.map((s) => <SpeakerViewButton key={s} id={s} />)
                                }
                              </div>
                            </>
                          )}
                        </div>
                      )}
                      {event.longevity_experience && (
                          <>
                            <div className="flex flex-col md:flex-row flex-wrap gap-3">
                              <LongevityExperienceViewButton key={event.longevity_experience} id={event.longevity_experience} />
                            </div>
                          </>
                      )}
                      {event.additional_info && (
                          <>
                            <div className="flex flex-col md:flex-row flex-wrap gap-3">
                              <MoreInfoViewButton key={event.additional_info} id={event.additional_info} additionalInfo={event.additional_info} />
                            </div>
                          </>
                      )}
                      {event.faculty_slides && dayjs().isAfter(event.endDayJS) && (
                        <div className="flex flex-col md:flex-row flex-wrap gap-3">
                            <button onClick={() => { window.open(event.faculty_slides, '_blank') }} className="px-3 py-2 hover:opacity-75 font-semibold active:opacity-100 rounded-md cursor-pointer disabled:opacity-30 disabled:cursor-default bg-cyan text-white">
                              View Slides
                            </button>
                        </div>
                      )}
                    </div>
                  </div>
              </div>
              );
            })}
          </div>
        </div>

    </div>
  );
}

//#region helper components
const SpeakerViewButton = ({id}: { id: string }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const { attendees } = useAttendeeStore();

  const attendee = attendees.find(s => s.id === id);
  if(attendee){
    const name = attendee!.name
    return (
      <>
        <button onClick={() => { setOpenDialog(!openDialog) }} className="px-3 py-2 hover:opacity-75 font-semibold active:opacity-100 rounded-md cursor-pointer disabled:opacity-30 disabled:cursor-default bg-gold text-black">
          {name}
        </button>
        {openDialog && <AttendeeDetailDialog id={id} closeModal={() => { setOpenDialog(false) }} />}
      </>
    )

  }
  else return <></>
}

const LongevityExperienceViewButton = ({id}: { id: string }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const { sponsors } = useSponsorStore();

  const sponsor = sponsors.find(s => s.id === id);
  if(sponsor){
    const name = sponsor!.name
    return (
      <>
        <button onClick={() => { setOpenDialog(!openDialog) }} className="px-3 py-2 hover:opacity-75 font-semibold active:opacity-100 rounded-md cursor-pointer disabled:opacity-30 disabled:cursor-default bg-teal text-white">
          Book / More Info
        </button>
        {openDialog && <SponsorDetailDialog id={sponsor.id} closeModal={() => { setOpenDialog(false) }} />}
      </>
    )

  }
  else return <></>
}

const MoreInfoViewButton = ({id, additionalInfo}: { id: string, additionalInfo: string }) => {
  const [openDialog, setOpenDialog] = useState(false);

    return (
      <>
        <button onClick={() => { setOpenDialog(!openDialog) }} className="px-3 py-2 hover:opacity-75 font-semibold active:opacity-100 rounded-md cursor-pointer disabled:opacity-30 disabled:cursor-default bg-teal text-white">
          More Info
        </button>
        {openDialog && (
          <Transition appear show={true} as={Fragment}>
          <Dialog as="div" className="relative z-50" onClose={() => setOpenDialog(false)}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/50" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="relative w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <XMarkIcon onClick={() => setOpenDialog(false)} className="w-8 h-8 text-darkGold absolute right-2 top-2 cursor-pointer opacity-50 hover:opacity-75 active:opacity-100" />
                    <div className="text-black p-4 text-lg">
                      {additionalInfo ? parse(marked(additionalInfo).toString()) : "No additional information available."}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
          </Transition>
        )}
      </>
    )
}
//#endregion
