'use client'

import { useState, useEffect } from 'react'
import { Dialog, DialogPanel } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import logo from './assets/plat_logo.png'
import clsx from 'clsx'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

const navigation = [
  { name: 'SCHEDULE', href: '/schedule' },
  { name: 'DIRECTORY', href: '/directory' },
  { name: 'EXPERIENCES', href: '/experiences' },
  // { name: 'MAPS', href: '/maps' },
  { name: 'PARTNERS', href: '/partners' },
  //{ name: 'INFO', href: '/info' },
]
const moreInfo = [
  { name: 'CONTACT', href: '/contact' },
  { name: 'Longevity Olympics', href: '/olympics' },
  { name: 'daily Interest Form', href: '/interest', goto:'https://airtable.com/appPeFYwbGSBgDGkJ/pagTuQ148i1N5ULP0/form' },
  { name: '2025 Programs', href: '/programs', goto:'https://payments.a360.com/programs'},
]

const mobileNavigation = [
  { name: 'SCHEDULE', href: '/schedule' },
  { name: 'DIRECTORY', href: '/directory' },
  { name: 'LONGEVITY EXPERIENCES', href: '/experiences' },
  { name: 'Longevity Olympics', href: '/olympics' },
  { name: 'PARTNERS', href: '/partners' },
  { name: 'CONTACT', href: '/contact' },
  { name: 'daily Interest Form', href: '/interest', goto:'https://airtable.com/appPeFYwbGSBgDGkJ/pagTuQ148i1N5ULP0/form' },
  { name: '2025 Programs', href: '/programs', goto:'https://payments.a360.com/programs'},
]

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const location = useLocation()
  const currentPath = location.pathname
  const navigate = useNavigate();

  useEffect(() => {
    if (currentPath == '/interest') {
      window.open("https://airtable.com/appPeFYwbGSBgDGkJ/pagTuQ148i1N5ULP0/form", '_blank')
    }
    if (currentPath == '/programs') {
      window.open("https://payments.a360.com/programs", '_blank')
    }
  }, [currentPath])

  return (
    <header className="bg-platBlack">
      <nav aria-label="Global" className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8">
        <div className="flex lg:flex-1">
          <a href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img alt="" src={logo} className="h-6 w-auto" onClick={() => navigate('/schedule')} />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <Link to={item.href} key={item.name} className={clsx("text-lg font-semibold leading-6 ", currentPath.includes(item.href) && "text-gold")}>
              {item.name}
            </Link>
          ))}


          <Menu as="div" className="relative inline-block text-left">
            <div>
              <MenuButton className={clsx("text-lg font-semibold leading-6 ", moreInfo.map((item) => item.href).includes(currentPath) && "text-gold")}>
                MORE/INFO
              </MenuButton>
            </div>

            <MenuItems
              transition
              className="absolute right-0 z-10 mt-2 w-64 origin-top-right bg-platBlack rounded-md shadow-lg border border-gray-500 ring-1 ring-gray-200 ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="py-1">
                {moreInfo.map((item) => (
                    <MenuItem key={item.name}>
                      {!item.goto ? (
                      <Link
                        to={item.href}
                        className="uppercase block px-4 py-2 text-lg font-semibold text-white data-[focus]:bg-darkGold"
                      >
                        {item.name}
                      </Link>
                      ) : (
                        <a onClick={() => window.open(item.goto, '_blank')} target="_blank" className="uppercase block px-4 py-2 text-lg font-semibold text-white data-[focus]:bg-darkGold">
                          {item.name}
                        </a>
                      )}
                  </MenuItem>
                ))}

              </div>
            </MenuItems>
          </Menu>
        </div>
        {/* <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <Link to="/register" className="text-sm font-semibold leading-6 text-white">
            Log in <span aria-hidden="true">&rarr;</span>
          </Link>
        </div> */}
      </nav>
      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-platBlack px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                alt=""
                src={logo}
                className="h-6 w-auto"
              />
            </a>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-400"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/25">
              <div className="space-y-2 py-6">
                {mobileNavigation.map((item) => (
                  !item.goto ? (
                    <Link
                      key={item.name}
                      to={item.href}
                      onClick={() => setMobileMenuOpen(false)}
                      className={clsx("-mx-3 block uppercase rounded-lg px-3 py-2 text-xl font-semibold leading-7", currentPath == item.href ? "text-gold" : "text-white")}
                    >
                      {item.name}
                    </Link>
                  ) : (
                    <a onClick={() => window.open(item.goto, '_blank')} target="_blank" className="-mx-3 block uppercase rounded-lg px-3 py-2 text-xl font-semibold leading-7">
                      {item.name}
                    </a>
                  )
                ))}
              </div>
              <div className="py-6">
                {/* <Link
                  to="/register"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                >
                  Log in
                </Link> */}
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  )
}
